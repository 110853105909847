import React from 'react';
import {
  FaFacebookSquare,
  FaGithubSquare,
  FaTwitterSquare,
  FaLinkedin,
  
} from 'react-icons/fa';
import Logo from "../assets/logo.png";
import { useNavigate } from 'react-router-dom';

const Footer = () => {

  const navigate = useNavigate()

/* first value: width of the image, second value: height */
  const backgroundImageStyle = {
    backgroundImage: `url("https://images.unsplash.com/photo-1505413687799-90481dfc0203?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80")`,
    backgroundSize: "cover 50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  };




  return (
    <div style={backgroundImageStyle}>

<div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
      <div>
        <img src={Logo} className="mr-4 h-8" alt="" />
        <p className='py-4'>
          Eliminate septic tanks in your community and protect water quality.</p>
        <div className='flex md:w-[75%] my-6 gap-4'>
        <a href="https://www.facebook.com/profile.php?id=100057406631145">
              <FaFacebookSquare size={30} />
            </a>
            <a href="https://www.linkedin.com/company/onsyte-performance/" >
              <FaLinkedin size={30} />
            </a>
        </div>
      </div>
      {/* <div className='lg:col-span-2 flex justify-between mt-6'>
        <div>
          <h6 className='font-light text-gray-400'>Support</h6>
          <ul>
            <li className='py-2 text-sm'>Documentation</li>
            <li className='py-2 text-sm'>Guides</li>
          </ul>
        </div>
        <div>
          <h6 className='font-light text-gray-400'>Company</h6>
          <ul>
            <li className='py-2 text-sm'>About</li>
            <li className='py-2 text-sm'>Blog</li>
          </ul>
        </div>
        <div>
          <h6 className='font-light text-gray-400'>Legal</h6>
          <ul>
            <li className='py-2 text-sm'>Claim</li>
            <li className='py-2 text-sm'>Policy</li>
          </ul>
        </div>
      </div> */}
    </div>
    </div>
  
  );
};

export default Footer;
