import React from "react";
import Overlay from "../assets/Overlay.png";
import CEOCard from "./CEOcard";

const WaterArticle = ({ title, author, date, content, imageUrl }) => {
  return (
    <div className="max-w-3xl mx-auto mt-8 p-4 bg-white rounded-lg shadow-md">
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-24 lg:max-w-7xl">
          <div className="relative overflow-hidden rounded-lg lg:h-auto">
            <img
              src="https://static.wixstatic.com/media/d5d149_6f6f467dd91648baa2796042f087711b~mv2.jpg"
              alt=""
              className="h-full w-full object-cover object-top"
            />
          </div>
        </div>
      </div>
      <h1 className="text-3xl font-semibold mb-4">
        Water & Wastes Digest Discusses Septic Tank Alternatives
      </h1>
      <div className="flex items-center text-gray-600">
        <p className="ml-2">March 29, 2022</p>
      </div>
      <div className="mt-4 prose">
        <div>
          <p>
            In Florida, we have known about this problem for a long time. Excess
            nitrogen in our waterways linked to nutrient pollution –
            eutrophication – causes harmful algal blooms. The algal blooms
            deplete the oxygen in the water and can kill seagrasses and corals,
            which provide food and habitat for manatees and other aquatic life.
            The harmful algal blooms also cause fish kills, which are ugly and
            can make the water unsafe for humans. Until recently the solutions
            for mitigating eutrophication were limited and typically expensive,
            but a new sewage treatment system called distributed wastewater
            treatment (DWT) is now available that could replace a leading source
            of reactive nitrogen in the forms of ammonium and nitrate – septic
            tanks. Recent tests prove this new technology works, and with
            widespread application, the problem of harmful algal blooms could be
            significantly reduced and the state’s waterways restored to a more
            natural and healthy state. Dr. Ann Shortell, former executive
            director of the St. John’s Water Management District, thinks DWT is
            a game changer. “There is no reason why this new method —
            distributed wastewater treatment — won’t work in any area that has
            septic systems near water resources,” Shortell said. “DWT looks like
            an effective solution and I’m very excited about its potential.”
          </p>
          <h3 className="text-xl font-semibold my-4">
            Distributed Wastewater Treatment Effectiveness for Excessive Algae
          </h3>
          <p className="indent-3">
            The problem of accelerated algae growth has been the subject of
            academic study and public debate for more than 30 years. Scientific
            research has identified septic tanks as a significant contributor of
            reactive nitrogen to Florida’s groundwater. This is especially true
            in areas with high concentrations of homes with septic tanks.
          </p>
          <p className="indent-3">
            DWT was recently tested in areas of Apopka and St. Cloud, Florida.
            Meanwhile, a team at Florida Atlantic University Harbor Branch in
            Ft. Pierce worked with Onsyte, the developers of DWT systems, and
            the Florida Department of Environmental Protection (FDEP) to conduct
            official tests of the system in Lake Hamilton during 2021.
          </p>
          <p>The results of the tests were extremely encouraging:</p>
          <ul className="list-disc pl-6 mt-4">
            <li>
              Total Kjeldahl Nitrogen (TKN) levels in effluent from the DWT
              system showed a 90% removal rate, from 125 mg/L to 13 mg/L.
            </li>
            <li>Ammonia was reduced by 88%, from 80 mg/L to 9.8 mg/L.</li>
            <li>
              Effluent had 8 mg/L nitrate, lower than the U.S. EPA standard for
              nitrate in drinking water of 10 mg/L.
            </li>
            <li>
              DWT removed 92% of the fecal coliforms, 97% of the total suspended
              solids, and 98% of the Carbonaceous Biochemical Demand (CBOD)
            </li>
          </ul>
          <h3 className="text-xl font-semibold my-4">
            Why Are Septic Tanks a Problem?
          </h3>
          <p className="indent-3">
            Septic tanks often fail for three reasons: poor maintenance, high
            groundwater tables, and Florida’s geology. Many septic tanks are
            decades old and if not maintained properly, do not function well and
            can allow untreated sewage to escape into the environment. Florida’s
            typically high water table and porous sandy soils also contribute to
            the problem as they do not sufficiently treat the effluent from
            septic systems and allow high amounts of nutrients and other
            contaminants into the groundwater. These nutrients make their way
            into local bodies of water where they cause algal growth to explode.
          </p>
          <p className="indent-3">
            According to the University of Florida Institute of Food and
            Agriculture, there are 2.6 million septic tanks in the state
            discharging about 426 million gallons of wastewater per day into
            Florida’s environment. As a result, the state is seeing an
            increasing trend in harmful algal blooms in freshwater, estuarine
            and marine environments in urbanized areas of Florida, a double
            whammy for both Florida residents and the tourism industry.
          </p>
          <h3 className="text-xl font-semibold my-4">
            DWT vs. Traditional Solution Costs
          </h3>
          <p className="indent-3">
            In the past, some cities have solved the problem by building
            municipal wastewater treatment systems. This is a great solution,
            but it is not always a practical one. Smaller communities struggle
            to come up with sufficient funding for treatment plants and
            collection systems. Installing underground pipes can be extremely
            difficult in many areas of Florida because of its high water table,
            sandy soils and limestone geology. Problems like these can make
            installation of infrastructure cost millions and take years, if not
            decades, to complete.
          </p>
          <p className="indent-3">
            DWT systems seem to be the proverbial “better mousetrap.” DWT
            technology solves the problems created by septic systems and avoids
            the costs associated with municipal sewer collection and treatment.
          </p>
          <p className="indent-3">
            “A septic system typically costs about $12,000 installed,” explained
            Bryan Nelson, mayor of Apopka, a city of 52,000 located northwest of
            Orlando. “A distributed wastewater system is about $15,000, so the
            costs are comparable. Converting a home to a municipal sewer system
            costs about $50,000, so you can put in three DWT systems for the
            cost of one conversion to a municipal system. The economics alone
            tell you DWT is a great solution.”
          </p>
          <h3 className="text-xl font-semibold my-4">
            How the Distributed Wastewater Treatment Works
          </h3>
          <p className="indent-3">
            A DWT unit is about the same size as a septic tank, but it treats
            sewage much differently. DWT has been described as similar to a
            municipal treatment plant. The process utilizes a three-chambered,
            suspended-growth activated-sludge sequencing batch reactor with
            biological nitrogen removal. The effluent it produces is treated to
            a level that does not require a drain field for percolation,
            although use of a drainfield can achieve even higher levels of
            treatment. The relatively low nutrient levels are released into the
            environment and the •• •• “There is no reason why this new method —
            distributed wastewater treatment — won’t work in any area that has
            septic systems near water resources.” treatment process covers a
            much smaller footprint.
          </p>
          <p className="indent-3">
            Furthermore, DWT units can be monitored remotely by public works
            employees. If the unit fails in any way, technicians will be
            notified and the problem is repaired without involving the
            homeowner. Typically, homeowners may never know a problem occurred.
          </p>
          <p className="indent-3">
            “We monitor DWT units at the municipal level with our SCADA
            (supervisory control and data acquisition) technology,” Nelson said.
            His city, Apopka, has done a significant amount of testing of DWT
            units with superlative results. “If anything goes wrong, we notify
            the OnSyte people and they take care of the problem immediately.”
          </p>
          <p className="indent-3">
            OnSyte, based in Suwanee, Georgia, is the developer of DWT
            technology and provides local support for communities that use the
            system.
          </p>
          <h3 className="text-xl font-semibold my-4">
            How to Transition to DWT
          </h3>
          <p className="indent-3">
            The costs of transitioning communities to DWT systems is, in some
            cases, being subsidized by grants from the FDEP and the St. Johns
            River Water Management District, which just completed testing DWT in
            Lake Hamilton. By all measures, the tests proved that DWT units
            greatly reduce nitrogen levels in discharged effluent, making the
            wastewater much safer to release into the environment.
          </p>
          <p className="indent-3">
            The path to transition is simple enough. Local utility departments
            or contracted technicians conduct a routine home inspection before
            permitting a DWT installation. Plumbing modifications are typically
            unnecessary. Once the unit is installed, the homeowner will notice
            no change in wastewater disposal. The homeowner pays a monthly
            wastewater fee similar to the cost of municipal treatment. The
            system is maintained by the local water treatment district.
          </p>
          <p className="indent-3">
            A DWT residential unit requires 120V of alternating current on a
            20-amp circuit to operate the onboard controller and pumps, sensors,
            and air compressor. Total electricity usage per month is about
            1.3kWh, approximately $4 to $5. Loss of electrical power, however,
            will not cause backups as the wastewater will continue to flow
            through the DWT unit and receive minimal sewage treatment even
            during a power outage; toilets and sinks can be used without issue.
          </p>
          <h3 className="text-xl font-semibold my-4">
            Healing Florida’s Waters
          </h3>
          <p className="indent-3">
            The problem of excessive algal blooms from nitrate pollution has
            plagued Florida for decades and it is getting worse as its
            population grows. The tide, however, may be turning as more people
            transition from septic systems to DWT systems. DWT has shown to
            significantly reduce the amount of nitrogen entering the
            environment. As concentrations of ammonia and nitrate in the
            waterways diminish, Mother Nature begins the healing process, and it
            does not take long. Soon, local surface waters get cleaner and
            healthier, seagrasses can grow, fish populations rebound and mana-
            tee habitats are restored.
          </p>
          <p className="indent-3 mb-8">
            “We must protect our water quality,” Nelson said. “We want the
            springs and rivers to be healthy and accessible not only for our
            kids and grandkids, but for our great-great grandchildren, too. We
            are determined to make a positive impact on our environment, and
            distributed wastewater treatment may be our best opportunity to
            solve the problem of accelerated algae growth.” <b>WWD</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WaterArticle;
