import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import zoom from '../assets/zoom1.JPG'
import unit1 from '../assets/Unit R.1.JPG'
import unit2 from '../assets/Unit M.2.JPG'

export default function TechStats() {
    return (
        <div className="bg-white py-32 px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-base leading-7 text-gray-700">
                <h1 className="mt-2 text-3xl font-light tracking-tight text-gray-900 sm:text-4xl">Perfomance Dashboard and SCADA</h1>
                <p className="mt-6 text-xl leading-8">
                The OnSyte Performance system uses IoT to manage and optimize small-scale wastewater treatment units in real-time, with a licensed professional overseeing the operations. Its innovative dashboard also delivers essential data on system capacity, wastewater flows, and nitrogen removal.
                </p>
                <div className="mt-10">
                    <figure className="mt-16  max-w-2xl">
                        <img
                            className="aspect-video rounded-xl bg-gray-50 object-cover"
                            src={zoom}
                            alt=""
                        />
                        <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
                            <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
                            The OnSyte SCADA system provides remote, wireless access to the OnSyte treatment units and provides complete control by professional wastewater operators.
                        </figcaption>
                    </figure>

                    <div className='bg-white py-16 px-4 flex gap-4 flex-col md:flex-row'>
                        <div className='flex flex-col items-center'>
                            <img className='my-4' src={unit1} alt='/' />
                            <div>
                                <h1 className='md:text-xl sm:text-xl text-2xl font-light py-2'>OnSyte Residential</h1>
                                <p>
                                    A Distributed Wastewater Treatment Unit designed to optimally treat wastewater from a single residential home. Full integration with the OnSyte SCADA.
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col items-center'>
                            <img className='my-4' src={unit2} alt='/' />
                            <div>
                                <h1 className='md:text-xl sm:text-xl text-2xl font-light py-2'>OnSyte Commercial</h1>
                                <p>
                                    Modular Treatment Units to handle average daily flows of 1,000 - 5,000 gallons per day.
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col items-center'>
                            <img className='my-4' src={unit2} alt='/' />
                            <div className='flex flex-col justify-center'>
                                <h1 className='md:text-xl sm:text-xl text-2xl font-light py-2'>Large Flow Treatment Units</h1>
                                <p>
                                Large Flow treatments units to handle small communities, office parks, green-field development or any other need.
                                </p>
                            </div>
                        </div>
                    </div>



                    <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Benefits of the Distributed Sewer Approach</h2>
                    <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600 max-w-2xl">
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Regulatory.</strong> Individual septic tanks, including advanced nutrient removal systems, are regulated by the Department of Health (DOH), which requires a separate permit for every unit installed. Using the DWTS approach, a single permit from the Department of Environmental Protection (DEP) for a Distributed Wastewater Treatment System can be obtained for an integrated system of wastewater treatment units over a large area.
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Speed and Scale.</strong> As opposed to homeowner-initiated retrofits, OnSyte DWTUs can be quickly deployed in integrated, networked systems of hundreds or even thousands of units. DWTS technology can quickly provide centralized wastewater treatment system efficiency to areas of your community that are currently out of reach of existing wastewater treatment plants.
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Environmental.</strong> The significance of nutrient pollution has been recognized as a threat to the environment. Moreover, failing septic tanks are public health hazards that need to be eliminated to address exposure to E. coli, Enterococci, and other pathogens.
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Economic.</strong> With a professional system operator overseeing the operation and maintenance of the DWTS under a long-term service contract, stable, low-cost sewer rates can be established. The savings made possible through a large, regional contract are passed on to the utility, which in turn can pass on a modest wastewater service fee to its customers. This will improve acceptance with current septic tank users.
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Social.</strong> OnSyte DWTUs offer a minimally invasive and non-disruptive alternative to converting septic systems to centralized sewer systems, ensuring no negative financial impacts on non-participants. The installation process is efficient, avoiding the need for retrofitting established neighborhoods with new sewer infrastructure. This not only preserves the quality of life but also proves to be cost-effective. The virtual connection allows for on-demand installation, enabling a flexible retrofit program based on community-established criteria, ensuring a fair and incentive-driven approach.
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Utility Benefits.</strong> A homeowner connected to an OnSyte DWTU does not impact the capacity of an existing wastewater treatment plant. Any reserve capacity of the central system can be preserved for future growth in the community and as a valuable buffer against sewage overflows associated with storms and floods. Additionally, avoiding the need for extending force mains and lift stations across the community saves money and energy.
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
