import React from 'react';
import Typed from 'react-typed';
import backgroundImage from '../assets/Backgroundimage.png'
import { useNavigate } from 'react-router-dom';



const Hero = () => {

  const navigate = useNavigate()

  const backgroundImageStyle = {
    backgroundImage: `url("https://images.unsplash.com/photo-1505413687799-90481dfc0203?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80")`,
    backgroundSize: "100% 90%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
  };








  return (

    <div className='text-white z-0' style={backgroundImageStyle}>
    {/* <video className='z-0 absolute w-full' src={video} autoPlay muted loop/> */}
    {/* <div className='relative z-2 max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center' > */}
    <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center' >
      
      <p className='text-[#fefefe] font-bold p-2'>
        Leading the revolution of wastewater treatment.
      </p>
      <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
        Sewer on Demand Anywhere.
      </h1>
      <div className='flex justify-center items-center'>
        <p className='md:text-3xl sm:text-4xl text-sm font-bold py-4'>
          Combining
        </p>
        <Typed
          className='md:text-3xl sm:text-4xl text-sm font-bold md:pl-4 pl-2'
          strings={['Multi-stage Wastewater Treatment', 'IoT Monitoring', 'AI Optimization']}
          typeSpeed={50}
          backSpeed={50}
          loop
        />
      </div>
      <p className='md:text-2xl text-xl font-light text-white'>OnSyte delivers distributed wastewater treatment solutions that reduce groundwater pollution and provide utility-like simplicity beyond the reach of traditional sewer systems.</p>
      <button onClick={()=> navigate("/technology")} className='bg-[#007edf] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white'>Our Technology</button>
    </div>
  </div>
  
  );
};

export default Hero;
