import React from 'react';
import Dashboard from '../assets/dashboard-new.png'
import { useNavigate } from 'react-router-dom';

const Analytics = () => {

  const navigate = useNavigate()
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Dashboard} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#008ddf] font-bold '>Real-time monitoring.</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Optimized Dashboard</h1>
          <p>

          The OnSyte Performance system uses IoT to manage and optimize small-scale wastewater treatment units in real-time, with a licensed professional overseeing the operations. This dashboard delivers essential data on system capacity, wastewater flows, and nitrogen removal.
          </p>
          <button onClick={()=>navigate('/technology')} className='bg-black text-[#0091df] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;