const posts = [
  {
    id: 1,
    title: 'OnSyte Announces John Cheslik as CEO',
    href: 'newceo',
    description:
      "Cheslik brings depth of experience in business strategy, operations, deployment for growth, and high-quality customer service across the environmental, water, and wastewater markets in North America.",
    imageUrl:
      'https://res.cloudinary.com/dm17kkmbh/image/upload/v1696285992/Overlay_os1shr.png',
    date: 'October 02, 2023',
    datetime: '2023-10-02',
    category: { title: 'Marketing', href: 'https://mydigitalpublication.com/publication/?i=736649' },
    author: {
      name: 'Madi Easterling',
      role: 'Marketing Manager',
      href: '#',
      imageUrl:
        'https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735297/IMG-9548_u44mpq.jpg',
    },
  },
  {
    id: 2,
    title: 'OnSyte Performance Raises Growth Investment from Ember Infrastructure',
    href: 'https://www.prnewswire.com/news-releases/onsyte-performance-raises-growth-investment-from-ember-infrastructure-301865176.html',
    description:
      `OnSyte Performance, LLC ('OnSyte' or the 'Company') announced today it has received a growth investment from funds managed by Ember...`,
    imageUrl:
      'https://res.cloudinary.com/dm17kkmbh/image/upload/v1696285992/Overlay_os1shr.png',
    date: 'August 15, 2023',
    datetime: '2023-08-15',
    category: { title: 'Marketing', href: 'https://mydigitalpublication.com/publication/?i=736649' },
    author: {
      name: 'Madi Easterling',
      role: 'Marketing Manager',
      href: '#',
      imageUrl:
        'https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735297/IMG-9548_u44mpq.jpg',
    },
  },
  {
    id: 3,
    title: 'Water & Wastes Digest Discusses Septic Tank Alternatives',
    href: 'waterandwaste',
    description:
      "The February edition of Water and Wastes Digest's cover story focused on Septic Tank alternatives for areas with impaired water resources. The article highlighted the City of Apopka's Septic to Distributed Sewer Program where OnSyte Performance partnered with the City to eliminate septic tanks and provide homeowners with utility wastewater service. By every metric, the City's program has been a success and is a model that can be used by any city, town, or municipality to eliminate septic tank pollution.",
    imageUrl:
      'https://static.wixstatic.com/media/d5d149_6f6f467dd91648baa2796042f087711b~mv2.jpg',
    date: 'March 29, 2022',
    datetime: '2022-03-29',
    category: { title: 'Marketing', href: 'https://mydigitalpublication.com/publication/?i=736649' },
    author: {
      name: 'Scott Forrester',
      role: 'Chief Business Development Officer',
      href: '#',
      imageUrl:
        'https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735660/IMG-9533_wa6s3h.jpg',
    },
  },
  {
    id: 4,
    title: 'ABC News Spot Covers Distributed Sewer',
    href: 'abc-news',
    description:
      "The February edition of Water and Wastes Digest's cover story focused on Septic Tank alternatives for areas with impaired water resources. The article highlighted the City of Apopka's Septic to Distributed Sewer Program where OnSyte Performance partnered with the City to eliminate septic tanks and provide homeowners with utility wastewater service. By every metric, the City's program has been a success and is a model that can be used by any city, town, or municipality to eliminate septic tank pollution.",
    imageUrl:
      '/images/abc-cover.jpeg',
    date: 'January 05, 2022',
    datetime: '2022-01-05',
    category: { title: 'Marketing', href: 'https://mydigitalpublication.com/publication/?i=736649' },
    author: {
      name: 'Madi Easterling',
      role: 'Marketing Manager',
      href: '#',
      imageUrl:
        'https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735297/IMG-9548_u44mpq.jpg',
    },
  },
  // {
  //   id: 3,
  //   title: '2021, A Year in Review',
  //   href: 'yearin2021',
  //   description:
  //     "It’s hard to believe that 2021 is in the rearview mirror and it’s already 2022! As we’ve done once or twice before, we wanted to provide a summary of the recent progress and an outlook for Distributed Sewer in Florida for 2022:",
  //   imageUrl:
  //     'https://static.wixstatic.com/media/d5d149_535edb9c117a447ca7945af2db4d0674~mv2.jpg/v1/fill/w_720,h_894,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/d5d149_535edb9c117a447ca7945af2db4d0674~mv2.jpg',
  //   date: 'Jan 16, 2022',
  //   datetime: '2022-01-16',
  //   category: { title: 'Marketing', href: '#' },
  //   author: {
  //     name: 'Scott Forrester',
  //     role: 'Chief Business Development Officer',
  //     href: '#',
  //     imageUrl:
  //       'https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735660/IMG-9533_wa6s3h.jpg',
  //   },
  // },
  // {
  //   id: 4,
  //   title: 'OnSyte Presents at Florida OSTDS Technical Advisory Committee Meeting',
  //   href: 'techadvisory',
  //   description:
  //     'Jeff Littlejohn of OnSyte was invited to present by the Florida Onsite Treatment and Disposal System (OSTDS) Technical Advisory Committee (TAC).',
  //   imageUrl:
  //     'https://static.wixstatic.com/media/d5d149_48e8383e36c541d0b25ecd6752584f1e~mv2.jpg/v1/fill/w_569,h_569,al_c,lg_1,q_80,enc_auto/d5d149_48e8383e36c541d0b25ecd6752584f1e~mv2.jpg',
  //   date: 'Mar 16, 2020',
  //   datetime: '2020-03-16',
  //   category: { title: 'Marketing', href: '#' },
  //   author: {
  //     name: 'Scott Forrester',
  //     role: 'Chief Business Development Officer',
  //     href: '#',
  //     imageUrl:
  //       'https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735660/IMG-9533_wa6s3h.jpg',
  //   },
  // },
  // {
  //   id: 5,
  //   title: 'Reflections and New Opportunities',
  //   href: 'reflections',
  //   description:
  //     'In January The FDEP issued a Distributed Wastewater Treatment System Permit to the Florida Governmental Utility Authority (FGUA) which covers all of Lake County. It was issued with a limit of 20,000 gallons per day and will cover the first 90-100 residential units. As we approach the limit, we will go back to FDEP and request a capacity increase.',
  //   imageUrl:
  //     'https://static.wixstatic.com/media/d5d149_e56ee51ead6e4019812e1bdc0184ff5b~mv2.jpg/v1/fill/w_1280,h_720,al_c,q_85,enc_auto/d5d149_e56ee51ead6e4019812e1bdc0184ff5b~mv2.jpg',
  //   date: 'Mar 16, 2020',
  //   datetime: '2020-03-16',
  //   category: { title: 'Marketing', href: '#' },
  //   author: {
  //     name: 'Scott Forrester',
  //     role: 'Chief Business Development Officer',
  //     href: '#',
  //     imageUrl:
  //       'https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735660/IMG-9533_wa6s3h.jpg',
  //   },
  // },
  // More posts...
]

export default function Annoucements() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Find the latest updates from our blog.</h2>
          <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
            {posts.map((post) => (
              <article key={post.id} className="relative isolate flex flex-col gap-8 lg:flex-row">
                <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:w-64 lg:shrink-0">
                  <img
                    src={post.imageUrl}
                    alt=""
                    className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div>
                  <div className="flex items-center gap-x-4 text-xs">
                    <time dateTime={post.datetime} className="text-gray-500">
                      {post.date}
                    </time>
                    <a
                      href={post.category.href}
                      className="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {post.category.title}
                    </a>
                  </div>
                  <div className="group relative max-w-xl">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a href={post.href} rel="noopener noreferrer">
                        <span className="absolute inset-0" />
                        {post.title}
                      </a>
                    </h3>
                    <p className="mt-5 text-sm leading-6 text-gray-600">{post.description}</p>
                  </div>
                  <div className="mt-6 flex border-t border-gray-900/5 pt-6">
                    <div className="relative flex items-center gap-x-4">
                      <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-2xl object-cover bg-gray-50" />
                      <div className="text-sm leading-6">
                        <p className="font-semibold text-gray-900">
                          <a href={post.author.href}>
                            <span className="absolute inset-0" />
                            {post.author.name}
                          </a>
                        </p>
                        <p className="text-gray-600">{post.author.role}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}