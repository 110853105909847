import React from 'react'
import video from '../assets/multipass480.mov'

const HomeVideo = () => {
  return (

    <div className='w-full bg-white py-16 px-4 flex items-center'>
     
     <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">


    <video className='items-center' src={video} width="950" height="600" controls autoPlay muted>
    </video>

     </div>


   
  </div>
    




  )
}

export default HomeVideo