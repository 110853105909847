import { Fragment, useState } from 'react'
import { Dialog, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  SignalIcon,
  CursorArrowRaysIcon,
  WrenchScrewdriverIcon,
  QuestionMarkCircleIcon,
  SquaresPlusIcon,
  XMarkIcon,
  HomeModernIcon
  
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Logo from "../../assets/logo.png";
import { useNavigate} from 'react-router-dom';





function CompanyItemButton({item}){
    const navigate = useNavigate()

    const handleClick = ()=>{
        navigate(item.href)
    }
    return (
        <div className="relative rounded-lg p-4 hover:bg-gray-50">
          <button onClick={handleClick} className="block text-sm font-semibold leading-6 text-gray-900">
            {item.name}
            <span className="absolute inset-0" />
          </button>
          <p className="mt-1 text-sm leading-6 text-gray-600">{item.description}</p>
        </div>
      );

}

function MobileCompanyButton({item}){
    const navigate = useNavigate()

    const handleClick = ()=>{
        navigate(item.href)
    }
    return (
        <div className="relative rounded-lg p-4 hover:bg-gray-50">
          <button onClick={handleClick} className="block text-sm font-semibold leading-6 text-gray-900">
            {item.name}
            <span className="absolute inset-0" />
          </button>
          <p className="mt-1 text-sm leading-6 text-gray-600">{item.description}</p>
        </div>
      );

}

function ProductItemButton({item}){
    const navigate = useNavigate()
    
    const handleClick = ()=>{
        navigate(item.href)
    }

    return (
        <div
          key={item.name}
          className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
        >
          <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-sky-600" aria-hidden="true" />
          </div>
          <div className="flex-auto">
            <button onClick={handleClick} className="block font-semibold text-gray-900">
              {item.name}
              <span className="absolute inset-0" />
            </button>
            <p className="mt-1 text-gray-600">{item.description}</p>
          </div>
        </div>
      );


}

function MobileProductButton({item}){
    const navigate = useNavigate()
    
    const handleClick = ()=>{
        navigate(item.href)
    }

    return (
        <button
        onClick={handleClick}
        className="group -mx-3 flex items-center gap-x-6 rounded-lg p-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
      >
        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
          <item.icon className="h-6 w-6 text-gray-600 group-hover:text-sky-600" aria-hidden="true" />
        </div>
        {item.name}
      </button>
      );


}

export default function Header() {

    const company = [
        { name: 'Meet Our Team', href: '/about', description: 'Get insights about our company values and mission to empower others.' },
        {
          name: 'Partners',
          href: '/partners',
          description: 'Learn about our dedicated partners within the community.',
        },
      ]
    const ecoboard = [
        { name: 'City of Apopka Program', href: '/publicapopka', description: 'Distributed wastewater treatment restoring Wekiva Springs' },
        {
          name: 'Utility Login',
          href: '/apopka',
          description: 'Further insight to performance metrics.',
        },
      ]

      const products = [
        { name: 'Communities', description: 'Get a better understanding of your traffic', href: '/communities', icon: HomeModernIcon },
        { name: 'Utilities', description: 'Speak directly to your customers', href: '/utilities', icon: WrenchScrewdriverIcon },
        { name: 'Developers', description: 'Cost-effective connections', href: '/developers', icon: SignalIcon },
        { name: 'FAQs', description: 'Frequently Asked Questions', href: '/faqs', icon: QuestionMarkCircleIcon },
      ]
      const callsToAction = [
        { name: 'Watch demo', href: '/homevideo', icon: PlayCircleIcon },
        { name: 'Contact sales', href: '/contact', icon: PhoneIcon },
      ]

  const navigate = useNavigate()

  function handleClick(url) {
    navigate(url);
  }
 
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-white">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <button onClick={()=> navigate('/')} 
          type='button'
          className="-m-1.5 p-1.5">
            <span className="sr-only">Onsyte Performance</span>
            <img className="h-12 w-auto pr-2" src={Logo} alt="Onsyte Logo" />
          </button>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-8">
     

          <button onClick={()=>navigate("/technology")} className="text-md font-semibold leading-6 text-gray-900 hover:text-sky-700">
            Technology
          </button>
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-gray-900 hover:text-sky-700">
              Solutions
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                 <ProductItemButton key={item.name} item={item} />
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                   <button
                   key={item.name}
                   onClick={() => navigate(item.href)}
                   className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                 >
                   <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                   {item.name}
                 </button>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-gray-900 hover:text-sky-700">
              Company
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-96 rounded-3xl bg-white p-4 shadow-lg ring-1 ring-gray-900/5">
                {company.map((item) => (
               
                 <CompanyItemButton key={item.name} item={item}/>
                ))}
              </Popover.Panel>
            </Transition>
          </Popover>
          <button onClick={()=> navigate('/iaas')} className="text-md font-semibold leading-6 text-gray-900 hover:text-sky-700">
            Infrastructure-as-a-Service
          </button>
          <button onClick={()=> navigate('/announcements')} className="text-md font-semibold leading-6 text-gray-900 hover:text-sky-700">
            Announcements
          </button>

          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-gray-900 hover:text-sky-700">
              Eco-Dashboard
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-96 rounded-3xl bg-white p-4 shadow-lg ring-1 ring-gray-900/5">
                {ecoboard.map((item) => (
               
                 <CompanyItemButton key={item.name} item={item}/>
                ))}
              </Popover.Panel>
            </Transition>
          </Popover>

      
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {/* <a href="/contact" className="text-md font-semibold leading-6 text-gray-900">
            Get a quote <span aria-hidden="true">&rarr;</span>
          </a> */}
          <button
          onClick={()=> navigate("/contact")}
        type="button"
        className="rounded-md bg-sky-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
      >
        Contact Us
      </button>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 flex w-full flex-col justify-between overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="p-6">
            <div className="flex items-center justify-between">
              <button onClick={()=> navigate("/")} className="-m-1.5 p-1.5">
                <span className="sr-only">Onsyte Performance</span>
                <img
                  className="h-8 w-auto"
                  src={Logo}
                  alt=""
                />
              </button>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                {products.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="group -mx-3 flex items-center gap-x-6 rounded-lg p-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                      </div>
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="space-y-2 py-6">
                  <a
                    href='/iaas'
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Infrastructure-as-a-Service
                  </a>

                  {company.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="/contact"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky bottom-0 grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50 text-center">
            {callsToAction.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="p-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
              >
                {item.name}
              </a>
            ))}
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
