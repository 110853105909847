const faqs = [
]

export default function Utilities() {
  return (
    <div className="bg-white">
      <div aria-hidden="true" className="relative">
        <img
          src="https://static.wixstatic.com/media/d5d149_8b03395b62fd4a8c9cea35ea00adb0e9~mv2.jpg/v1/fill/w_1920,h_852,fp_0.50_0.50,q_85,enc_auto/d5d149_8b03395b62fd4a8c9cea35ea00adb0e9~mv2.jpg"
          alt=""
          className="h-96 w-full object-cover object-center"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-white" />
      </div>

      <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Utility Solutions</h2>
          <p className="mt-4 text-gray-500">
          Regardless of the size of population you serve or the geographic footprint of your jurisdiction, OnSyte has a cost-effective solution for utilities to service unreachable areas and add new rate payers. 
          </p>
        </div>

        <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <p className="mt-2 max-w-2xl text-xl font-light leading-7 text-gray-600">
          The benefits of an Onsyte Distributed Wastewater Treatment System Include:
        </p>
        <ul className="mt-8 list-disc">
          <li>
          One permit can give you access to thousands of additional customer connections. Extend your rate base to the limits of your jurisdiction or franchise service area.
          </li>
          <li>
          Pricing competitive with tradtional sewer.
          </li>
          <li>
          Uses standard State Department of Enviromental Protection domestic wastewater facility permit process, which is familiar to operators and regulators, with low administrative overhead
          </li>
           <li>
           Utilizing a developer agreement, the DTWU equipment and collection system can be funded by the developer and transferred to the utility after build out and system commissioning. With this approach, a DWTS can complement the expansion of a wastewater utility and generate significant, long-term, recurring revenue.
          </li>
           <li>
           DWTUs and SCADA are built from the ground up with industry standard, tested and proven components.
          </li>
        </ul>
        <div className="mt-5">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

   
      </div>
    </div>
  )
}
