import React from 'react'

const Apopka = () => {
  return (
    <>
    <div className="mx-auto max-w-2xl lg:text-center">
    <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
    The City of Apopka has launched a first-of-its-kind program to help protect and restore the iconic Wekiva Springs.
    </p>
    <p className="mt-6 text-lg leading-8 text-gray-600">
    In collaboration with the St. Johns River Water Management District, Apopka has obtained funding assistance to replace septic tanks with a distributed wastewater treatment technology from OnSyte Performance. Check out this dashboard to see how our systems are performing! 
   
    </p>
  </div>
    
    <div className="flex justify-center items-center h-screen">
      
    <div className="relative w-3/4 h-3/4">
      {/* <iframe
        className="absolute top-0 left-0 w-full h-full"
        src=""
        frameborder="0"
      /> */}

<iframe title="City of Apopka Dashboard" className="absolute top-0 left-0 w-full h-full" src="https://app.powerbi.com/view?r=eyJrIjoiYTE2YzU4ZTAtMGMyZi00NDA2LTg4ZTAtYjczOWFhMWJmMDA5IiwidCI6ImI5YmI2Y2I4LTQ5YTAtNGFjZi1iODAzLWZhYWEyNWFhMzUwMCIsImMiOjZ9" frameborder="0" allowFullScreen="true"></iframe>


    </div>
    </div>
    </>
  )
}




export default Apopka