import video from '../assets/multipass480.mov'

const posts = [
  {
    id: 1,
    role: 'Central Sewer System',
    href: '#',
    description:
      "Connecting to an existing sewer collection system is probably the best option if it's nearby and there is available treatment capacity at the plant. It can be costly to extend sewer lines, and if a mandatory connection requirement is involved, the associated social cost may be unacceptable to the community.",
  },
  {
    id: 2,
    role: 'Advanced Technology Septic Tanks',
    href: '#',
    description:
      "If central sewer is not an option, upgrading the septic tank is a possible solution, and it doesn't have a high social cost. However, the nitrogen removal performance is only 50%, the systems are not continuously monitored or professionally operated, and the administrative costs and maintenance burden on the homeowner are high."

  },
  {
    id: 3,
    role: 'Distributed Wastewater Treatment System (DWTS)',
    href: '/technology',
    description:
      'A DWTS functions like a public wastewater collection and treatment system but without the need for a connection to each customer. A DWTS achieves 80-90% nitrogen removal and has low operating costs and no maintenance burden on the homeowner. It also avoids the high capital and social costs associated with sewer line extensions.',
  },
]

export default function Example() {
  return (
    <div className="bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto flex max-w-2xl flex-col items-start justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row">
          <div className="w-full lg:max-w-lg lg:flex-auto">
            <h2 className="mb-3 text-3xl font-light tracking-tight text-gray-900 sm:text-4xl">
              Alternative Approaches to Septic Tank Replacement
            </h2>
              {/* <iframe src="https://www.youtube.com/embed/r9jwGansp1E" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
  

            <video src={video} width="850" height="600" controls autoPlay muted>
       </video>
            <p className="mt-6 text-sm leading-8 text-gray-600">
              Many communities face challenges related to septic tanks, especially nitrogen pollution from septic tanks impacting our springs and estuaries. There are also tens of thousands of septic tanks in older neighborhoods with known public health issues, such as unsafe levels of fecal coliform. Once the decision is made by a community that septic tanks need to be replaced, what alternatives exist, and what are their advantages and disadvantages?
            </p>
          </div>
          <div className="w-full lg:max-w-xl lg:flex-auto">
            <h3 className="sr-only">Job openings</h3>
            <ul className="-my-8 divide-y divide-gray-100">
              {posts.map((opening) => (
                <li key={opening.id} className="py-8">
                  <dl className="relative flex flex-wrap gap-x-3">
                    <dt className="sr-only">Role</dt>
                    <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900">
                      <a href={opening.href}>
                        {opening.role}
                        <span className="absolute inset-0" aria-hidden="true" />
                      </a>
                    </dd>
                    <dt className="sr-only">Description</dt>
                    <dd className="mt-2 w-full flex-none text-base leading-7 text-gray-600">{opening.description}</dd>
                    <dd className="mt-4 flex items-center gap-x-3 text-base leading-7 text-gray-500">
                      <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300" aria-hidden="true">
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      {opening.location}
                    </dd>
                  </dl>
                </li>
              ))}
            </ul>
            <div className="mt-8 flex border-t border-gray-100 pt-8">
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
