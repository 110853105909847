import React from "react";
import Overlay from "../assets/Overlay.png";
import CEOCard from "./CEOcard";

const Newceo = ({ title, author, date, content, imageUrl }) => {
  return (
    <div className="max-w-3xl mx-auto mt-8 p-4 bg-white rounded-lg shadow-md">
      <CEOCard />
      <h1 className="text-3xl font-semibold mb-4">
        Onsyte Announces John Cheslik as CEO
      </h1>
      <div className="flex items-center text-gray-600">
        <p className="ml-2">October 2, 2023</p>
      </div>
      <div className="mt-4 prose">
        <div>
          <p>
            OnSyte Performance, LLC (“OnSyte” or the “Company”) announced today
            that water & wastewater industry veteran John Cheslik has joined as
            Chief Executive Officer as the Company embarks on a new phase of
            growth.
          </p>
          <p className="mt-8">
            Cheslik most recently served as Senior Vice President Growth and
            Business Development for Veolia North America’s Municipal Water
            business, focusing on new offerings, integrated
            design-build-operate, and traditional water/wastewater/reuse
            business growth. Prior to joining Veolia, Mr. Cheslik served as the
            Chief Operating Officer of Suez Water Environmental Services (with
            responsibility for operations & maintenance services as well as
            integrated project delivery including “smart” offerings) and Senior
            Vice President of Operations at Suez Advanced Solutions (with
            responsibility over equipment, products, maintenance & asset
            management services). Based in Buford, Georgia, OnSyte offers
            state-of-the-art treatment technology in a continuously monitored,
            wirelessly controlled system that is operated and maintained by
            licensed wastewater professionals. These distributed wastewater
            treatment units (“DWTUs”) can be deployed individually or as
            networks and are well suited for wastewater utility, municipality,
            commercial, industrial, and residential applications. OnSyte can
            enable wastewater utilities to provide service where previously cost
            prohibitive as well as enable private developers and real estate
            owners access to the benefits of central sewer where otherwise
            unavailable.
          </p>

          <p className="mt-8">
            Each DWTU has advanced nutrient pollution removal capabilities,
            utilizing a suspended-growth, activated-sludge process in a
            miniaturized sequencing batch reactor tied to a proprietary
            programmable logic controller, operating in a secure networked
            environment. Licensed wastewater operators monitor these networks
            and are able to optimize system performance and perform remote
            supervisory control if a unit is operating out of specification.
            Additionally, the Company’s cloud-based performance dashboard
            product provides customers with real-time access across their
            network of DWTUs, delivering important information such as
            wastewater flows, system capacity, and nitrogen removal. OnSyte
            offers flexible solutions including hardware and software,
            installation services, and ongoing monitoring and maintenance based
            on customer needs. The Company also provides a fully-financed,
            turnkey Infrastructure-as-a-Service ("IaaS") offering. IaaS allows
            the replacement of aging septic tanks or the development of new
            construction with modern distributed wastewater treatment technology
            at no upfront cost to real estate developers, builders,
            municipalities, or homeowners while simultaneously providing many of
            the benefits of central sewer connections including predictable
            monthly bills, reduced operating risks, and superior treatment and
            environmental benefits versus traditional septic.
          </p>

          <p className="mt-8">
            “OnSyte’s solutions can create tremendous value for customers across
            a range of markets, and I couldn’t be more excited and honored to be
            joining the team,” said Cheslik. “I am looking forward to building
            on the team’s proven technology and established proofs-of-concept in
            a variety of municipal and commercial frameworks.” The Company
            previously announced a growth equity investment from funds
            affiliated with Ember Infrastructure in June 2023.
          </p>

          <p className="mt-8">
            “We are thrilled to have John at the helm to guide OnSyte through
            what we anticipate to be a period of rapid growth,” said Ember
            Partner and OnSyte board member Caleb Powers. “His background and
            expertise are a perfect fit for OnSyte, spanning municipal utilities
            and agencies, private utilities, and real estate developers.”
          </p>
        </div>
      </div>
    </div>
  );
};

export default Newceo;
