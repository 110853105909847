import React from 'react';
import Rowed from '../assets/rowedlineup.JPG'
import { useNavigate } from 'react-router-dom';

const Technology = () => {

  const navigate = useNavigate()

  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Rowed} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#008ddf] font-bold '>A revolution in removal of pollution.</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Distributed Wastewater Technology</h1>
          <p>
            At the heart of an OnSyte DWTU is an advanced nutrient-removal wastewater treatment process, utilizing a suspended-growth, activated-sludge process in a miniaturized Sequencing Batch Reactor (SBR). Once treated, the clean water from the DWTU typically is directed to an existing drainfield. The OnSyte treatment method is essentially a scaled down version of the
             treatment technology commonly found in large wastewater treatment plants.
        
          </p>
          <button onClick={() => navigate("/technology")} href="/technology" className='bg-black text-[#0091df] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default Technology;
