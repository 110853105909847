const AbcNewsFrame = () => {
  return (
    <div className="max-w-3xl mx-auto mt-8 p-4 bg-white rounded-lg shadow-md">
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-24 lg:max-w-7xl">
          <h1 className="text-3xl font-semibold mb-4">
            ABC News Spot Covers Distributed Sewer
          </h1>
          <div className="flex items-center text-gray-600">
            <p className="ml-2">January 05, 2022</p>
          </div>
          <div className="relative overflow-hidden rounded-lg lg:h-auto">
            <video width="100%" height="auto" controls>
              <source src="/videos/abc-news-spot.mov" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbcNewsFrame;
