import dashboard from '../assets/dashboard-new.png'
import TechStats from './TechStats'

export default function PageTechnology() {
    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h1 className="mt-2 text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">Distributed Wastewater Technology</h1>
            <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
              <div>
                <p>
                Distributed Sewer uses state-of-the-art treatment technology in a continuously monitored, wireless control system, operated and maintained by wastewater professionals.
               
                </p>
                <p className="mt-8">
                The OnSyte system represents a performance breakthrough for distributed treatment. 
                In fact, the OnSyte system achieves total nitrogen reduction in excess of 80% and outstanding reductions in BOD, TSS, and coliform bacteria. 
                The key to achieving advanced wastewater treatment at this small scale is the patent pending OnSyte Performance programmable logic controller (PLC), operating in a networked environment (commonly called the Internet of Things, or IOT). 
                The network of OnSyte DWTUs is remotely monitored by a licensed wastewater operator who will optimize system performance and perform remote supervisory control if a unit is operating out of specification.
                
                </p>
              </div>
              <div>
                <p>
                  With an OnSyte DWTS, there is no need for a wastewater collection system with its network of sewer laterals, mains, manholes, lift stations, and other infrastructure necessary to collect waste and transport it to a central wastewater treatment plant.
                 Each OnSyte treatment system usually is installed in just one day, typically adjacent to the existing septic tank that will be decommissioned. Due to its virtual connection to the utility, an OnSyte unit can be deployed quickly, even at remote locations.
                </p>
                <p className="mt-8">
                At the heart of an OnSyte DWTU is an advanced nutrient-removal wastewater treatment process, utilizing a suspended-growth, activated-sludge process in a miniaturized Sequencing Batch Reactor (SBR). Once treated, the clean water from the DWTU typically is directed to an existing drainfield. The OnSyte treatment method is essentially a scaled down version
                of the treatment technology commonly found in large wastewater treatment plants.
                </p>
              </div>
            </div>

          </div>
        </div>
        <div className="relative overflow-hidden pt-16 lg:pt-20">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              className="rounded-xl"
              src={dashboard}
              alt=""
            />
            <div className="relative" aria-hidden="true">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
            </div>
          </div>
        </div>
        <TechStats/>
      </div>
    )
  }
  