import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "What is Distributed Sewer?",
    answer:
      'Distributed Sewer is a new method of providing sewer treatment to homeowners anywhere, regardless of location. This is not just another "plug-in" advanced septic tank. It is just like having sewer service, but without the need to connect the home to a central plant through pipes.',
  },
  {
    question: "How does it work?",
    answer:
      "A miniature wastewater treatment unit is installed underground at a residence to provide sewer service. The unit is about the same size as a regular septic tank, but that’s where the similarities end! A small but powerful computer and network interface mounts to the side of the house, like an electric meter. The computer allows the utility to monitor and operate the treatment unit from a remote location.",
  },
  {
    question: "How much does it cost to connect?",
    answer:
      "The average Distributed Sewer connection cost ranges based on individual site conditions. However, thanks to cost-sharing programs across the State of Florida and many other states across the U.S., some communities are eligible for state funding. The utility will determine any out-of-pocket costs to the homeowner prior to connecting the system.",
  },
  {
    question: "Do I have to maintain it?",
    answer:
      "Once the homeowner is connected to the treatment system, a standard monthly sewer bill from the utility covers all operating and maintenance costs associated with the system, just like every other sewer system. There’s nothing else for the homeowner to do! As long as the homeowner does not damage any of the installed equipment, the system has no additional limitations and can process the same wastes as a central sewer plant. Just like a septic tank or a sewer plant, flushing strong chemicals or non-biodegradable items can damage the equipment or the environment.",
  },
  {
    question: "Does the unit need to be pumped out?",
    answer:
      'Solids are captured and recycled in a process that filters it from the incoming wastewater in a dedicated chamber. After the wastewater is fully treated, a portion is returned to the chamber to "digest" the solids and assist in their breakdown. The result is very low accumulation and infrequent need to pump out the unit.',
  },
  {
    question: "What advantage does Distributed Sewer offer homeowners?",
    answer:
      "Being a utility customer offers many advantages. There will never be a need to schedule septic tank inspections or pump-outs, and there is no worry that the toilet won’t flush following rain events or during power outages. The sewer utility bill covers all costs associated with the treatment unit, and home resale values are often higher when utility services are included. Finally, there is the satisfaction of knowing you are part of the solution to reduce pollution and help protect our springs and waterways.",
  },
  {
    question: "What advantage does Distributed Sewer offer communities?",
    answer:
      "Distributed Sewer systems do not require a network of pipes and pumps to connect homes to central sewer plants. This means no major construction impacts to neighborhoods, trees, yards and streets.",
  },

  {
    question: "How do I sign up?",
    answer:
      "Many programs are offered through your local government or utility, contact them to sign up. If they do not, head over to our contact page and we will be in touch!",
  },
  // More questions...
];

export default function FAQs() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-9xl px-6 py-15 sm:py-32 lg:py-40 lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
