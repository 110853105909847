import image from '../assets/Subdivision.png'
import { CogIcon, HomeModernIcon, BuildingLibraryIcon, BuildingOffice2Icon, BuildingOfficeIcon, HomeIcon, } from '@heroicons/react/20/solid'
import IAASContact from './IAASContact'




const incentives = [
    {
        name: 'Zero Upfront Capital Expenditures',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-fast-checkout-light.svg',
        description: "IAAS allows customers to replace aging septic tanks or develop new construction with modern distributed wastewater treatment technology at NO upfront cost to developer, builder, municipality, or homeowner.",
    },
    {
        name: 'Universal Sewer Access Under a Single Permit',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg',
        description: "OnSyte distributed wastewater treatment units (DWTUs) are remotely monitored by licensed wastewater operators, who optimize system performance in real-time. With this capability sewer services can be offered anywhere under a single permit which authorizes hundreds and thousands of units.",
    },
    {
        name: 'Produce Cleaner Effluent for Decades',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-light.svg',
        description:
            "OnSyte systems protect valuable surface waters by significantly reducing nitrogen and suspended solids that pollute local surface waters and harm the environment. Our systems achieve total nitrogen reduction in excess of 80% and outstanding reduction in Biochemical Oxygen Demand, Total Suspended Solids, and coliform bacteria.",
    },
    {
        name: 'Accessibility',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-chat-light.svg',
        description:
            "Expands service area into rural areas previously unreachable by conventional sewer.",
    },
]


const features = [


    {
        name: 'Developers',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
        icon: CogIcon,
      },
      {
        name: 'Homeowners Associations',
        description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
        icon: HomeIcon,
      },
      {
        name: 'Community Development Districts',
        description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus voluptas blanditiis et.',
        icon: HomeModernIcon,
      },
      {
        name: 'Municipalities with, or without, existing sewer utilities',
        description: 'Iure sed ab. Aperiam optio placeat dolor facere. Officiis pariatur eveniet atque et dolor.',
        icon: BuildingOfficeIcon,
      },
      {
        name: 'Unincorporated communities',
        description: 'Laudantium tempora sint ut consectetur ratione. Ut illum ut rem numquam fuga delectus.',
        icon: BuildingOffice2Icon,
      },
      {
        name: 'County Governments',
        description: 'Culpa dolorem voluptatem velit autem rerum qui et corrupti. Quibusdam quo placeat.',
        icon: BuildingLibraryIcon,
      },


]


export default function NewIAAS() {
    return (
        <div className="bg-gray-50">
            <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
                <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
                    <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
                        <div>
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                                Universal sewer access with ZERO upfront costs.

                            </h1>
                            <br></br>
                            <h2 className="text-xl font-bold tracking-tight text-gray-900">
                                No expensive central collection system.


                            </h2>
                            <h2 className="text-xl font-bold tracking-tight text-gray-900">
                                Easier permitting vs traditional septic and no ongoing service obligations.



                            </h2>
                            <p className="mt-4 text-gray-500">
                                OnSyte’s Infrastructure-as-a-Service (IAAS) offering is a complete,
                                turnkey wastewater treatment solution that is available to any community, large or small.
                            </p>
                        </div>
                        <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100">
                            <img
                                src={image}
                                alt=""
                                className="object-cover object-center"
                            />
                        </div>
                    </div>
                    <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                        {incentives.map((incentive) => (
                            <div key={incentive.name} className="text-center sm:flex sm:text-left lg:block lg:text-center">
                                <div className="sm:flex-shrink-0">
                                    <div className="flow-root">
                                        <img className="mx-auto h-16 w-16" src={incentive.imageSrc} alt="" />
                                    </div>
                                </div>
                                <div className="mt-3 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                                    <h3 className="text-sm font-medium text-gray-900">{incentive.name}</h3>
                                    <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <h2 className="mt-14 text-xl font-bold tracking-tight text-gray-900">Distributed wastewater treatment technology without the initial expense, installation challenges, and ongoing service requirements. </h2>
              <p className="mt-8 text-center font-light text-xl">
              OnSyte’s Infrastructure-As-A-Service offering is available to:
              </p>

   
    <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-black">
                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                {feature.name}
              </dt>{' '}
            </div>
          ))}
        </dl>

              {/* <ul role="list" className="mt-8 max-w-xl space-y-5 text-gray-600">
            <li className="flex gap-x-3">
              <CogIcon className="mt-1 h-5 w-5 flex-none text-blue-800" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">Developers</strong>
              </span>
            </li>
            <li className="flex gap-x-3">
              <HomeIcon className="mt-1 h-5 w-5 flex-none text-blue-800" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">Homeowners Associations</strong>
              </span>
            </li>
            <li className="flex gap-x-3">
              <HomeModernIcon className="mt-1 h-5 w-5 flex-none text-blue-800" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">Community Development Districts</strong>
              </span>
            </li>
            <li className="flex gap-x-3">
              <BuildingOfficeIcon className="mt-1 h-5 w-5 flex-none text-blue-800" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">Municipalities with, or without, existing sewer utilities</strong>
              </span>
            </li>
            <li className="flex gap-x-3">
              <BuildingOffice2Icon className="mt-1 h-5 w-5 flex-none text-blue-800" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">Unincorporated communities</strong>
              </span>
            </li>
            <li className="flex gap-x-3">
              <BuildingLibraryIcon className="mt-1 h-5 w-5 flex-none text-blue-800" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">County Governments</strong>
              </span>
            </li>
          </ul> */}


          <IAASContact />
                </div>
            </div>
        </div>
    )
}