export default function Partners() {
  return (
    <div className="bg-white py-18 sm:py-32">
      <h2 className="mb-2 text-4xl text-center">Our Partners</h2>
      <p className="font-light mt-6 mb-6 text-center">A successful Distributed Sewer Program requires dedicated partners. OnSyte Performance is proud to be working closely with:</p>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[400px] mx-auto my-4' src='https://static.wixstatic.com/media/d5d149_5243bf1dba9547a5abd088355a9eb653~mv2.png/v1/fill/w_308,h_259,al_c,lg_1,q_85,enc_auto/Apopka.png' alt='/' />
        <div className='flex flex-col justify-center'>

          <p className='font-light text-lg'>
            The City of Apopka has launched a first-of-its-kind program to help protect and restore the iconic Wekiva Springs. In collaboration with the St. Johns River Water Management District, Apopka has obtained funding assistance to replace septic tanks with a distributed wastewater treatment technology from OnSyte Performance.
          </p>

        </div>
      </div>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[400px] mx-auto my-4' src='https://static.wixstatic.com/media/d5d149_4486c641626a4a9b9dd0971acfdc2b3d~mv2.jpg/v1/fill/w_315,h_315,al_c,lg_1,q_80,enc_auto/Lake%20Hamilton.jpg' alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='font-light text-lg'>
            The Town of Lake Hamilton was able to add sewer services to the list of services it provides to its residences thanks to a new distributed wastewater treatment system from OnSyte Performance.
          </p>
        </div>
      </div>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[400px] mx-auto my-4' src='https://static.wixstatic.com/media/d5d149_63733e857b0f4c8182501c982bb15d53~mv2.png/v1/fill/w_618,h_466,al_c,lg_1,q_85,enc_auto/FSCJ.png' alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='font-light text-lg'>
            OnSyte donated and installed a miniature wastewater treatment system for teaching, training and research at the Advanced Technology Center on FSCJ’s Downtown Jacksonville campus. In this working lab, students will be able to perform the functions of a licensed wastewater operator on a fully-functional, miniaturized wastewater treatment plant.
          </p>

        </div>
      </div>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[400px] mx-auto my-4' src='https://static.wixstatic.com/media/d5d149_39d9d335a2274838a215affdcd1d444b~mv2.png/v1/fill/w_574,h_479,al_c,lg_1,q_85,enc_auto/SJRWMD.png' alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='font-light text-lg'>

            The St. Johns River Water Management District and FDEP provided funding of $1.5 million to match a contribution of $500,000 from local governments or utilities. With this funding, it’s expected that approximately 200 septic tanks will be replaced, with an estimated reduction in nitrogen loading to springs of about 10 to 23 pounds per year per tank, depending on location and soil type.
          </p>

        </div>
      </div>
    </div>
  )
}