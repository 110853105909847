import {useNavigate} from "react-router-dom"
import Analytics from './Analytics'
import Technology from './Technology'
import Hero from './Hero'
import HomeVideo from "./HomeVideo"
// import About from './About'
// import Cards from './Cards'
  
const Home = () => {
  const navigate = useNavigate();

    
  return (
      <>
      <Hero/>
      <HomeVideo/>
      <Analytics/>
      <Technology/>
      </>
  )
};
  
export default Home;