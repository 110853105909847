import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/Navbar/Navbar";
import TailNav from "./components/Navbar/TailNav";
import About from "./components/About";
import Technology from "./components/Technology";
import Footer from "./components/Footer";
import Communities from "./components/Communities";
import FAQs from "./components/FAQs";
import Careers from "./components/Careers";
import IAAS from "./components/IAAS";
import Utilities from "./components/Utilities";
import TwoCommmunities from "./components/TwoCommunities";
import PageTechnology from "./components/PageTechnology";
import Announcements from "./components/Announcements";
import NewIAAS from "./components/NewIAAS";
import Partners from "./components/Partners";
import Developers from "./components/Developers";
import Contact from "./components/Contact";
import MainNavbar from "./components/MainNavbar";
import TestHome from "./components/TestHome";
import Header from "./components/Navbar/Header";
import HomeVideo from "./components/HomeVideo";
import Apopka from "./components/Apopka";
import PublicApopka from "./components/PublicApopka";
import Newceo from "./components/Newceo";
import WaterArticle from "./components/WaterArticle";
import ABCNewsFrame from "./components/ABCNewsFrame";


function App() {
  return (
    <div>
      <BrowserRouter>
        {/* <Navbar /> */}
        {/* <MainNavbar/> */}
        <Header />

        {/* <TailNav /> */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<h1>This is the login page</h1>} />
          <Route
            path="/listings"
            element={<h1>This is the property listings page</h1>}
          />
          <Route path="/about" element={<About />} />
          <Route path="/technology" element={<PageTechnology />} />
          <Route path="/communities" element={<TwoCommmunities />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/utilities" element={<Utilities />} />
          <Route path="/iaas" element={<NewIAAS />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/homevideo" element={<HomeVideo />} />
          <Route path="/apopka" element={<Apopka />} />
          <Route path="/publicapopka" element={<PublicApopka />} />
          <Route path="/newceo" element={<Newceo />} />
          {/* <Route path='/yearin2021' element={<WaterArticle/>}/> */}
          <Route path='/waterandwaste' element={<WaterArticle/>}/>
          <Route path='/abc-news' element={<ABCNewsFrame/>}/>
          {/* <Route path='/techadvisory' element={<TechArticle/>}/>
      <Route path='/reflections' element={<ReflectionArticle/>}/> */}

          {/* <Route exact path='/' element={<Hero/>}/>
      <Route path='/about' element={<About/>}/> */}
          {/* <Analytics />
      <Newsletter />
      <Technology /> */}
          {/* <Cards /> */}
          {/* <Footer /> */}
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
