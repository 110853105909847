import { useNavigate } from "react-router-dom"

const Developers = () => {

    const navigate = useNavigate()

    return (
        <div className="bg-white">
            <div aria-hidden="true" className="relative">
                <img
                    src="https://images.unsplash.com/photo-1533163238111-4a7ced54f2e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80"
                    alt=""
                    className="h-96 w-full object-cover object-center"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-white" />
            </div>

            <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
                <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">OnSyte Developer Solutions</h2>
                    <p className="mt-4 text-gray-500">
                        OnSyte has worked with Developers across many states in the U.S. If you have challenges related to sewer access, OnSyte can help.
                    </p>
                </div>

                <div className="mx-auto -mt-12 max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
                    <p className="mt-2 max-w-2xl text-xl leading-7 font-light text-gray-600">
                        Onsyte solutions provide:
                    </p>
                    <ul className="list-disc mt-5">
                        <li>
                            Sewer service to any location, regardless of proximity to a force main or gravity line
                        </li>
                        <li>
                            “Build to suit” sewer infrastructure which integrates with phased development plans
                        </li>
                        <li>
                            Potential recurring revenue opportunity for developer
                        </li>
                        <li>
                            Pricing competitive with traditional sewer
                        </li>
                        <li>
                            Uses standard State Department of Enviromental Protection wastewater facility permit process, which is familiar to operators and regulators, with low administrative overhead
                        </li>
                        <li>
                            If ongoing sewer responsibilities are not attractive, a developer agreement can be used to install the DTWU equipment and collection system and transfer it to a utility after build out and system commissioning.
                        </li>
                        <li>
                            Professional Operation to assure high quality of wastewater treatment
                        </li>
                        <li>
                            Ideal solution for environmentally sensitive areas
                        </li>
                        <li>
                            Allows you to achieve Utility sewered lot sizing versus septic service sizing.
                        </li>
                    </ul>

                    <button
                        type="button" onClick={()=> navigate("/faqs")}
                        className="mt-7 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        FAQs
                    </button>
                </div>


            </div>
        </div>
    )
}

export default Developers